@import '../../static/global.scss';

.download_layout {
    margin-top: 100px;
    width: 100%;
}

.download_label {
    font-size: 25pt;
    color: #9e9e9e;
    line-height: 25pt;
    font-weight: bold;
    margin-top: 50px;
    width: 100%;
    text-align: center;
}

.download-progress-contaner {
    width: 65%;
    margin-left: 17.5%;
    margin-top: 30px;
    .progress {
      margin-bottom: 0px;
      height: 14px;
    }
}

.download-file-name {
    font-size: 15pt;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

.download-status {
    font-size: 20pt;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    line-height: 25px;
}